<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <div id="main-wrapper">
          <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <JobInfoForm v-if="jobInfoData" v-on:on-click-send-button="editJobInfo" :is-edit="true" :job-info-data="jobInfoData" :validation-errors="validationErrors" :is-changed="isChanged"></JobInfoForm>
                </div>
              </div>
            </div>
          </section>
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import JobInfoForm from "@/views/jobinfo/component/JobInfoForm";
    import Vue from "vue";
    import MyPageContents from "@/views/components/MyPageContents.vue";
    import MyPageMixIn from "@/common/mypage_mixin";


    export default {
        name: "JobInfoList",
        components: {MyPageContents,Footer, Header, JobInfoForm},
        mixins: [MyPageMixIn],
        data: function () {
            return {
                isChanged: false
            }
        },
        computed: {
            jobInfoData: {
                get() {
                    if (!this.userData ||
                        !this.userData.userGroup ||
                        !this.userData.userGroup.company ||
                        !this.userData.userGroup.company.jobInfoList) {
                        return null;
                    }
                    return this.userData.userGroup.company.jobInfoList.find((jobInfo) => jobInfo.id.toString() === this.$route.query.id.toString());
                }
            }
        },
        methods: {
            async editJobInfo(body) {
                const response = await this.postApi("jobInfo/edit", body);
                if (response) {
                    this.isChanged = true;
                    this.userData = response.data.user;
                    Vue.prototype.$user = response.data.user;
                } else {
                    this.isChanged = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>