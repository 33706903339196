<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <div id="main-wrapper">
            <Header></Header>
            <section v-if="jobInfoData" class="fixed mypage-contents-fixed">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-sm-12 mx-auto job-contact-form-frame">
                            <div class="form-title" style="display: block; float: left">
                                <h1 style="text-align: left;">求人応募フォーム</h1>
                                <div style="display: flex;">
                                  <span class="job-contact-subtitle">企業名：{{ jobInfoData.company.name }}</span>
                                  <span  class="job-contact-subtitle">{{ jobInfoData.title }}</span>
                                </div>
                              <span class="job-contact-text">企業担当者が確認後、3営業日以内にメールで連絡が届きますので、しばらくお待ちください。</span>
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                <label>名字</label>
                                <p class="required-label">必須</p>
                              </div>
                              <input type="text" class="form-control" required placeholder="田中" v-model="familyName">
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                    <label>名前</label>
                                    <p class="required-label">必須</p>
                                </div>
                                <input type="text" class="form-control" required placeholder="太郎" v-model="lastName">
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                    <label>名字(カナ)</label>
                                    <p class="required-label">必須</p>
                              </div>
                              <input type="text" class="form-control" required placeholder="タナカ" v-model="familyKana">
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                <label>名前(カナ)</label>
                                <p class="required-label">必須</p>
                              </div>
                              <input type="text" class="form-control" required placeholder="タロウ" v-model="lastKana">
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                <label>年齢</label>
                                <p class="required-label">必須</p>
                              </div>
                              <input type="number" class="form-control" placeholder="30" required v-model="age">
                            </div>
                            <div class="form-group">
                              <div class="form-group-title-group">
                                <label>メールアドレス</label>
                                <p class="required-label">必須</p>
                              </div>
                              <input type="email" class="form-control" required placeholder="sesmedia@yurulica.com" v-model="mailAddress">
                            </div>
                            <div class="form-group">
                                <div class="form-group-title-group">
                                    <label>転職希望時期</label>
                                    <p class="required-label">必須</p>
                                </div>
                                <div class="form-control form-select" style="height: auto; margin: 0;">
                                    <ul class="no-ul-list">
                                        <li class="inline_block" v-for="desiredJobChangeDateType of desiredJobChangeDateTypes" :key="desiredJobChangeDateType">
                                            <input :id="`desiredJobChangeDate${desiredJobChangeDateType}`" class="radio-custom" :value="desiredJobChangeDateType" type="radio" v-model="desiredJobChangeDate">
                                            <label :for="`desiredJobChangeDate${desiredJobChangeDateType}`" class="radio-custom-label weak">{{getDesiredJobChangeDateText(desiredJobChangeDateType)}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="hasValidationError" class="error-area">
                                <template v-for="(value, key) in validationErrors">
                                    <p v-for="item in value" :key="key + item.validationText">
                                        ・{{"(" + getFormName(key) + ")" + item.validationText}}
                                    </p>
                                </template>
                            </div>

                          <div>
                            <p class="form-text"><router-link class="link-margin" to="/terms" target="_blank">利用規約</router-link>及び<router-link class="link-margin" to="/privacy" target="_blank">プライバシーポリシー</router-link>に同意の上、応募をお願いいたします。</p>
                          </div>
                            <div class="form-group">
                                <button type="submit" class="btn-md full-width pop-login form-button orange-btn" style="width: 100%; margin: 20px 0px 0px;" @click="onClickSendButton">応募する</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>

</template>


<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";
    import {DesiredJobChangeDate, getDesiredJobChangeDateText} from "@/common/enum";

    const formMapping = {
        familyName: "名字",
        lastName: "名前",
        familyKana: "名字(カナ)",
        lastKana: "名前(カナ)",
        age: "年齢",
        mailAddress: "メールアドレス",
        desiredJobChangeDate: "転職希望時期"
    };

    export default {
        name: "JobInfoForm",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],
        data: function () {
            return {
                jobInfoData: null,
                familyName: "",
                lastName: "",
                familyKana: "",
                lastKana: "",
                age: "",
                mailAddress: "",
                desiredJobChangeDate: DesiredJobChangeDate.Immediate
            }
        },
        created: function () {
            this.getJobInfo(this.$route.query.id);
        },
        mounted: function() {
            window.scrollTo(0, 0);
        },
        computed: {
            desiredJobChangeDateTypes: {
                get() {
                    return Object.values(DesiredJobChangeDate);
                }
            },
            hasValidationError: {
                get() {
                    return Object.values(this.validationErrors).length > 0;
                }
            }
        },
        methods: {
            getFormName: function (key) {
                return formMapping[key];
            },
            getDesiredJobChangeDateText: function (desiredJobChangeDateType) {
                return getDesiredJobChangeDateText(desiredJobChangeDateType);
            },
            async getJobInfo(id) {
                const response = await this.getApi("jobInfo/get", { id: id });
                if (response && response.data && response.data.jobInfo) {
                    this.jobInfoData = response.data.jobInfo;
                } else {
                    this.pushError("求人情報の取得に失敗しました。");
                }
            },
            async onClickSendButton() {
                const response = await this.postApi("jobInfo/contact", {
                    id: this.jobInfoData.id,
                    familyName: this.familyName,
                    lastName: this.lastName,
                    familyKana: this.familyKana,
                    lastKana: this.lastKana,
                    age: Number(this.age),
                    mailAddress: this.mailAddress,
                    desiredJobChangeDate: this.desiredJobChangeDate
                }, true);
                if (response) {
                    this.pushResult("応募完了", "応募が完了いたしました。企業担当者が確認の上、3営業日以内に連絡が届きますので、しばらくお待ちください。");
                }
            }
        }
    }
</script>

<style scoped>

</style>
